import React from "react";

import Title from "../../partials/title";
import Grid from "../../partials/grid";
import { PlayIcon } from "../../partials/video";

import { innerHTML } from "../../utilities";

class CircleVideo extends React.Component {
  componentDidMount() {}
  render() {
    const { image, link, classes } = this.props;
    if (!image) return null;

    const text = image.title.split(" | ");
    const Title1 = {
      text_alignment: "Left",
      size: "h1",
      text: text[0],
      highlight:
        text[0].lastIndexOf(" ") > -1
          ? text[0].slice(text[0].lastIndexOf(" "))
          : null,
      className: classes.Title
    };
    const Title2 =
      text.length === 2
        ? {
            text_alignment: "Left",
            size: "h2",
            text: text[1],
            className: classes.Title
          }
        : null;
    return (
      <Grid.Container className={classes.Root}>
        <div className={classes.Wrapper}>
          <div className={classes.TextTop}>
            <Title {...Title1} />
            {Title2 && <Title {...Title2} />}
          </div>
          <div className={classes.Limiter}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img
                src={image.source_url || image.url}
                alt=""
                className={classes.Image}
              />
              <span className={classes.PlayIcon}>
                <PlayIcon />
              </span>
            </a>
          </div>
          <div className={classes.TextBottom}>
            <Title text='"' highlight='"' className={classes.TextBottomIcon} />
            <div dangerouslySetInnerHTML={innerHTML(image.caption)} />
          </div>
        </div>
      </Grid.Container>
    );
  }
}

export default CircleVideo;
export { CircleVideo };
