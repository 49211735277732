import React from "react";
import classNames from "classnames";

const Item = function(props) {
  const {
    align,
    Mobile = 12,
    Small,
    SmallLandscape,
    Medium,
    MediumLarge,
    className,
    collapsed,
    classes,
    ...p
  } = props;
  const sizes = { Mobile, Small, SmallLandscape, Medium, MediumLarge };
  return (
    <div
      {...p}
      className={classNames(
        classes[align],
        classes.Item,
        Object.keys(sizes).map(size => {
          return classes[`${size}-${sizes[size]}`] || null;
        }),
        collapsed && classes.Collapsed,
        className
      )}
    />
  );
};
export default Item;
export { Item };
