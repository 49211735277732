import React from "react";
import classNames from "classnames";
import { parseHighlight } from "../../utilities";

function Title(props) {
  const {
    size = "H1",
    text_alignment,
    color,
    classes,
    text,
    highlight,
    settings,
    children,
    className,
    ...p
  } = props;
  if (color && color !== "#FFFFFF") {
    if (!("style" in p)) {
      p.style = {};
    }
    p.style.color = color;
  }
  return React.createElement(
    size.toLowerCase(),
    {
      className: classNames(classes[size], classes[text_alignment], className),
      ...p
    },
    !text && children ? children : parseHighlight(text, highlight)
  );
}
export default Title;
export { Title };
