export default {
  Root: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: 200,
    transform: "translate(-50%,-50%)",
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(to bottom, #171F27 0%, #393C42 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "@media (orientation: landscape)": {
      width: "150vw",
      height: "150vw"
    },
    "@media (orientation: portrait)": {
      width: "150vh",
      height: "150vh"
    }
  },
  Logo: {
    width: "100%",
    maxWidth: 200
  }
};
