import React from "react";
import withStyles from "react-jss";
import { Parallax, Background as ParallaxBackground } from "react-parallax";

import Video from "../partials/video";

const Styles = {
  Wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  Parallax: {
    width: "100%",
    height: "100%"
  },
  Image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  ParallaxBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%"
  },
  ParallaxImage: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    minWidth: "100%",
    minHeight: "100%"
  }
};

class Background extends React.Component {
  render() {
    const { parallax, background, children, classes } = this.props;
    if (!background || (!background.video && !background.image)) {
      return children;
    }
    if (parallax) {
      return this.Parallax();
    }
    return (
      <React.Fragment>
        <div className={classes.Wrapper}>{this.Background()}</div>
        {children}
      </React.Fragment>
    );
  }
  Parallax() {
    const strength = -400;
    return (
      <Parallax
        strength={strength}
        className={this.props.classes.Parallax}
        bgStyle={{ width: "100%", height: "100%" }}
      >
        {this.props.children}
        <ParallaxBackground className={this.props.classes.Parallax}>
          <div
            className={this.props.classes.ParallaxBackground}
            style={
              strength > 0
                ? { top: `-${strength}px` }
                : { bottom: `${strength}px` }
            }
          >
            {this.Background()}
          </div>
        </ParallaxBackground>
      </Parallax>
    );
  }
  Background() {
    const { classes } = this.props;
    const { video = null, image } = this.props.background;
    if (video) {
      return (
        <Video
          videos={video}
          controls={false}
          autoPlay
          playsInline
          loop
          forceMuted
          background
        />
      );
    }
    if (image) {
      return (
        <div
          className={classes.Image}
          style={{ backgroundImage: `url(${image.source_url || image.url})` }}
        />
      );
    }
    return null;
  }
}

export default withStyles(Styles)(Background);
export { Background };
