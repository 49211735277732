import { screen } from "../../styles/variables";
export default {
  Container: {
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },
  Fluid: {
    maxWidth: "none"
  },
  Full: {
    maxWidth: "none",
    width: "100%"
  },
  [screen.Medium.media()]: {
    Container: {
      maxWidth: 800
    }
  },
  [screen.MediumLarge.media()]: {
    Container: {
      maxWidth: 1200
    }
  }
};
