import gilroy_woff2 from "./static/fonts/gilroy-extrabold-webfont.woff2";
import gilroy_woff from "./static/fonts/gilroy-extrabold-webfont.woff";
import Typography from "./styles/typography";
export default {
  "@font-face": {
    fontFamily: "Gilroy",
    src: [
      `url(${gilroy_woff2}) format('woff2')`,
      `url(${gilroy_woff}) format('woff')`
    ],
    fontWeight: "normal",
    fontStyle: "normal"
  },
  "@global": {
    "*, *:before, *:after": {
      boxSizing: "border-box"
    },
    html: {
      fontSize: "62.5%"
    },
    body: {
      extend: Typography.base.default,
      margin: 0,
      padding: 0,
      color: "#fff",
      backgroundColor: "#000"
    },
    "h1,h2,h3,h4,h5,h6": {
      extend: Typography.base.headings
    },
    ...Typography.headings,
    p: {
      marginTop: 0,
      marginBottom: ".8em"
    }
  }
};
