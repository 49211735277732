import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "tocca";
import "typeface-tajawal";

import React from "react";
import ReactDOM from "react-dom";
import withStyles from "react-jss";

import App from "./App";
import Styles from "./App.styles";

import * as serviceWorker from "./serviceWorker";

import smoothscroll from "smoothscroll-polyfill";
import "./App.scss";

window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

ReactDOM.render(
  React.createElement(withStyles(Styles)(App)),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
