export default {
  Row: {
    marginLeft: -15,
    marginRight: -15,
    display: "flex",
    flexWrap: "wrap"
  },
  Collapsed: {
    marginLeft: 0,
    marginRight: 0
  },
  Top: {
    justifyContent: "flex-start"
  },
  Center: {
    justifyContent: "center"
  },
  Bottom: {
    justifyContent: "flex-end"
  },
  Left: {
    alignItems: "flex-start"
  },
  Middle: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  Right: {
    marginLeft: "auto"
  }
};
