import React from "react";

export default function() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57.578"
      height="40.738"
      viewBox="0 0 57.578 40.738"
    >
      <path
        fill="currentColor"
        opacity=".83"
        d="M45.635 0h-33.68A11.964 11.964 0 0 0 0 11.955v16.828a11.956 11.956 0 0 0 11.955 11.955h33.668a11.956 11.956 0 0 0 11.955-11.955V11.955A11.945 11.945 0 0 0 45.635 0zm-8.1 21.2l-15.748 7.508a.626.626 0 0 1-.9-.575V12.642a.628.628 0 0 1 .912-.562l15.753 7.983a.632.632 0 0 1-.012 1.137z"
      />
    </svg>
  );
}
