import React from "react";
import classNames from "classnames";

import Title from "../../partials/title";
import Grid from "../../partials/grid";
import Button from "../../partials/button";

import { innerHTML } from "../../utilities";

class ImageText extends React.Component {
  render() {
    const { image, title, text, link, classes } = this.props;
    return (
      <div className={classes.Root}>
        <div className={classes.Wrapper}>
          <Grid.Row>
            <Grid.Item Medium={6} className={classes.Half}>
              <div className={classes.Limiter}>
                <img
                  src={image.url || image.source_url}
                  alt=""
                  className={classes.Image}
                />
              </div>
            </Grid.Item>
            <Grid.Item Medium={6} className={classes.Half}>
              <div className={classNames(classes.Limiter, classes.TextLimiter)}>
                <Title {...title} className={classes.Title} />
                <div dangerouslySetInnerHTML={innerHTML(text)} />
                {link && (
                  <Button
                    comp="a"
                    href={link.url}
                    target={link.target}
                    className={classes.Link}
                  >
                    {link.title || link.url}
                  </Button>
                )}
              </div>
            </Grid.Item>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

export default ImageText;
