import React from "react";
import classNames from "classnames";
import { Keyframes, animated } from "react-spring/renderprops";

const FloatAnimation = Keyframes.Spring(async next => {
  while (true) {
    await next({
      from: { radians: 0 },
      to: { radians: 2 * Math.PI }
    });
  }
});

export const Float = props => {
  const { reset, rotate = 0, ...rest } = props;
  return (
    <FloatAnimation reset={reset} native config={{ duration: 2000 }}>
      {({ radians }) => (
        <React.Fragment>
          <animated.div
            {...rest}
            style={{
              willChange: "transform",
              transform: radians.interpolate(
                r =>
                  `translate3d(0, ${20 * Math.sin(r + (2 * Math.PI) / 2)}px, 0)`
              )
            }}
          />
          <animated.div
            style={{
              willChange: "transform",
              transform: radians.interpolate(
                r =>
                  `scale(${(0.7 * Math.sin(r + 2 * Math.PI)) / 2 +
                    1}) rotateX(75deg) rotateZ(${rotate}deg)`
              ),
              maxWidth: 200,
              width: "50%",
              paddingBottom: "30%",
              margin: "2rem auto 0",
              borderRadius: "50%",
              backgroundColor: "rgba(0,0,0,0.4)"
            }}
          />
        </React.Fragment>
      )}
    </FloatAnimation>
  );
};

class Rotate extends React.Component {
  state = {
    index: 0
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        index:
          this.state.index === this.props.rotate.length - 1
            ? 0
            : this.state.index + 1
      });
    }, 300);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { rotate, classes, ...props } = this.props;
    return (
      <div {...props}>
        {rotate.map((image, i) => (
          <img
            src={image.url || image.source_url}
            alt=""
            key={i}
            className={classNames(
              classes.Image,
              i > 0 ? classes.ImageStack : null
            )}
            style={{
              visibility: this.state.index === i ? "visible" : "hidden"
            }}
          />
        ))}
      </div>
    );
  }
}

export { Rotate };
