import { screen } from "../../styles/variables";
export default {
  Root: {
    padding: [60, 15],
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    minHeight: "100vh"
  },
  [screen.Medium.media()]: {
    Root: {
      paddingLeft: 60,
      paddingRight: 60,
      minHeight: `${(100 / 16) * 9}vw`
    }
  }
};
