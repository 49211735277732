import { screen } from "../../styles/variables";
const Styles = {
  Item: {
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15
  },
  Center: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  Left: {
    marginRight: "auto"
  },
  Right: {
    marginLeft: "auto"
  },
  Collapsed: {
    paddingLeft: 0,
    paddingRight: 0
  }
};

function AddBreakpoint(name, size) {
  let obj;
  if (size) {
    if (!(size.media() in Styles)) {
      Styles[size.media()] = {};
    }
    obj = Styles[size.media()];
  } else {
    obj = Styles;
  }
  for (let i = 0; i < 12; i++) {
    let rule = `${name}-${i + 1}`;
    let value = `${(100 / 12) * (i + 1)}%`;
    if (rule in obj) {
      obj[rule].width = value;
    } else {
      obj[rule] = {
        width: `${(100 / 12) * (i + 1)}%`
      };
    }
  }
  obj[`${name}-auto`] = {
    width: "auto"
  };
}

Object.keys(screen).forEach(name => {
  AddBreakpoint(name, screen[name]);
});

export default Styles;
