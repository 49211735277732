import VideoStyles from "../../partials/video/video.styles";
import { screen } from "../../styles/variables";
export default {
  Root: {
    padding: [60, 15],
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  Wrapper: {
    position: "relative",
    width: "100%"
  },
  Limiter: {
    position: "relative",
    width: "100%",
    maxWidth: 710,
    marginLeft: "auto",
    marginRight: "auto"
  },
  Title: {
    margin: 0
  },
  TextBottom: {
    position: "relative",
    paddingTop: 100
  },
  TextBottomIcon: {
    height: "0.65em",
    fontSize: "27rem",
    position: "absolute",
    margin: 0,
    transform: "translateY(-100%)"
  },
  Image: {
    borderRadius: "50%",
    width: "100%"
  },
  PlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff"
  },
  Circle: {
    position: "relative",
    zIndex: 0,
    borderRadius: "50%",
    overflow: "hidden",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "100%"
  },
  ControlsWrapper: {
    extend: VideoStyles.ControlsWrapper,
    padding: [0, 15]
  },
  ControlsTop: {
    display: "none"
  },
  ControlsBottom: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)"
  },
  [screen.Small.media()]: {
    Root: {
      paddingLeft: 60,
      paddingRight: 60,
      minHeight: `${(100 / 16) * 9}vw`
    },
    TextTop: {
      position: "absolute",
      zIndex: 2,
      top: 0,
      left: 0,
      maxWidth: "100%"
    },
    TextBottom: {
      position: "absolute",
      zIndex: 2,
      bottom: 0,
      left: "50%",
      maxWidth: "50%",
      paddingLeft: "19%"
    },
    TextBottomIcon: {
      height: "0.65em"
    }
  }
};
