import React from "react";

import Logo from "../../static/images/logo.svg";
import Grid from "../grid";
import Icon from "../icon";

class Footer extends React.Component {
  render() {
    const { links, classes } = this.props;
    return (
      <footer className={classes.Root}>
        <Grid.Container>
          <img src={Logo} alt={""} className={classes.Logo} />
          <div>
            {links && (
              <ul className={classes.Links}>
                {links.linkedin && (
                  <li>
                    <a
                      href={links.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.Link}
                    >
                      <Icon.linkedin />
                    </a>
                  </li>
                )}
                {links.facebook && (
                  <li>
                    <a
                      href={links.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.Link}
                    >
                      <Icon.facebook />
                    </a>
                  </li>
                )}
                {links.youtube && (
                  <li>
                    <a
                      href={links.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.Link}
                    >
                      <Icon.youtube />
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </Grid.Container>
      </footer>
    );
  }
}

export default Footer;
