import withStyles from "react-jss";
import UnstyledComponent, * as VideoExports from "./video";
import Styles from "./video.styles";

const Video = withStyles(Styles)(UnstyledComponent);
export default Video;
const PlayControl = withStyles(Styles)(VideoExports.PlayControl);
const VolumeControl = withStyles(Styles)(VideoExports.VolumeControl);
const VideoDefaultProps = VideoExports.defaultProps;
const { PlayIcon } = VideoExports;
export {
  Video,
  Styles,
  PlayControl,
  VolumeControl,
  VideoDefaultProps,
  PlayIcon
};
