import { screen } from "../../styles/variables";
export default {
  Root: {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    padding: [60, 15]
  },
  Wrapper: {
    width: "100%"
  },
  Half: {
    display: "flex",
    alignItems: "center"
  },
  Limiter: {
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  TextLimiter: {
    maxWidth: 430
  },
  Image: {
    width: "100%",
    marginBottom: "3rem"
  },
  Title: {
    marginBottom: ".5rem"
  },
  [screen.Medium.media()]: {
    Root: {
      paddingLeft: 60,
      paddingRight: 60,
      minHeight: `${(100 / 16) * 9}vw`,
      maxHeight: "100vh"
    },
    Limiter: {
      padding: [0, 30],
      maxWidth: "70%",
      marginLeft: "auto",
      marginRight: 0
    },
    TextLimiter: {
      marginLeft: 0
    }
  }
};
