import React from "react";

import Video, { VideoDefaultProps } from "../../partials/video";
import Title from "../../partials/title";
import Button from "../../partials/button";

class FullVideo extends React.Component {
  render() {
    const { video, link, classes } = this.props;
    const title = {
      text_alignment: "Center",
      size: "h1",
      text: video[0].title,
      highlight:
        video[0].title.lastIndexOf(" ") > -1
          ? video[0].title.slice(video[0].title.lastIndexOf(" "))
          : null,
      className: classes.Title
    };
    return (
      <div className={classes.Root}>
        <div className={classes.Wrapper}>
          <Video
            videos={video}
            screenMiddle={video => (
              <React.Fragment>
                <Title {...title} />
                {VideoDefaultProps.screenMiddle(video)}
              </React.Fragment>
            )}
            screenBottom={video => (
              <React.Fragment>
                {VideoDefaultProps.screenBottom(video)}
                {link && link.title && link.url && (
                  <Button
                    comp="a"
                    href={link.url}
                    target={link.target}
                    className={classes.Link}
                  >
                    {link.title}
                  </Button>
                )}
              </React.Fragment>
            )}
            background
          />
        </div>
      </div>
    );
  }
}

export default FullVideo;
export { FullVideo };
