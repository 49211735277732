import React from "react";
import classNames from "classnames";

import Title from "../../partials/title";
import Grid from "../../partials/grid";

class CircleImage extends React.Component {
  render() {
    const { image, classes, text_top_title, text_bottom_title } = this.props;
    const TextTop = Object.assign(
      {
        text_alignment: "Left"
      },
      text_top_title
    );
    const TextBottom = Object.assign(
      {
        text_alignment: "Left"
      },
      text_bottom_title
    );
    return (
      <Grid.Container className={classes.Root}>
        <div className={classes.Wrapper}>
          <div
            className={classNames(
              classes.TextTop,
              classes[`Text${TextTop.text_alignment}`]
            )}
          >
            <Title {...TextTop} className={classes.TitleTop} />
          </div>
          <img src={image.url} alt="" className={classes.Image} />
          <div
            className={classNames(
              classes.TextBottom,
              classes[`Text${TextBottom.text_alignment}`]
            )}
          >
            <Title {...TextBottom} />
          </div>
        </div>
      </Grid.Container>
    );
  }
}

export default CircleImage;
export { CircleImage };
