import { screen } from "../../styles/variables";
export default {
  Header: {
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
    width: "100%",
    padding: 15,
    opacity: 0,
    transition: "opacity .25s ease-in"
  },
  Display: {
    opacity: 1
  },
  Logo: {
    width: 100,
    maxWidth: "100%",
    height: "auto",
    marginTop: "1rem"
  },
  LogoRight: {
    float: "right"
  },
  [screen.Medium.media()]: {
    Header: {
      padding: [15, 30]
    }
  },
  [screen.MediumLarge.media()]: {
    Logo: {
      width: 160
    }
  }
};
