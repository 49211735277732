import React from "react";
import classNames from "classnames";

import Background from "../../partials/background";
import Grid from "../../partials/grid";
import Title from "../../partials/title";
import { innerHTML } from "../../utilities";

class HalfAndHalf extends React.Component {
  render() {
    const { left, right, classes } = this.props;
    return (
      <Grid.Row collapsed>
        <Grid.Item
          SmallLandscape={6}
          Medium={6}
          className={classNames(classes.Half, classes.LeftHalf)}
          collapsed
        >
          <Background background={left.background}>
            <div className={classNames(classes.Content, classes.ContentLeft)}>
              <Title {...left.title} />
            </div>
          </Background>
        </Grid.Item>
        <Grid.Item
          SmallLandscape={6}
          Medium={6}
          className={classNames(classes.Half, classes.RightHalf)}
          collapsed
        >
          <Background background={right.background}>
            <div
              className={classNames(classes.Content, classes.ContentRight)}
              dangerouslySetInnerHTML={innerHTML(right.content)}
            />
          </Background>
        </Grid.Item>
      </Grid.Row>
    );
  }
  getBackground(background) {
    try {
      return background.image.source_url || background.image.url || "";
    } catch (e) {
      return "";
    }
  }
}
export default HalfAndHalf;
export { HalfAndHalf };
