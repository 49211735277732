import { screen } from "../../styles/variables";
export default {
  PlayerWrapper: {
    position: "relative",
    minWidth: "100%",
    minHeight: "100%",
    zIndex: 1
  },
  BackgroundVideo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  VideoWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  Video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  Placeholder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  ControlsWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: [60, 15],
    opacity: 0,
    transition: "opacity .25s ease-in",
    zIndex: 2
  },
  ShowControls: {
    opacity: [1, "!important"]
  },
  Controls: {
    width: "100%",
    height: "100%",
    maxWidth: "100vw",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  ControlsScreen: {
    width: "100%",
    textAlign: "center"
  },
  ControlsTop: {
    extend: "ControlsScreen",
    alignSelf: "flex-start"
  },
  ControlsMiddle: {
    extend: "ControlsScreen"
  },
  ControlsBottom: {
    extend: "ControlsScreen",
    alignSelf: "flex-end"
  },
  TogglePlay: {
    textAlign: "center"
  },
  Button: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "inherit",
    "@global svg": {
      maxWidth: "100%",
      height: "auto"
    }
  },
  Volume: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    marginBottom: 30
  },
  MuteButton: {
    width: 46
  },
  VolumeSlider: {
    width: 150
  },
  [screen.Medium.media("max")]: {
    Volume: {
      display: "none"
    }
  }
};
