import { screen } from "../../styles/variables";
export default {
  Half: {
    position: "relative",
    padding: 15,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  Content: {
    position: "relative",
    zIndex: 5,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  ContentLeft: {
    maxWidth: 520
  },
  ContentRight: {
    maxWidth: 440,
    marginTop: "auto"
  },
  [screen.Small.media()]: {
    Half: {
      minHeight: "100vw"
    }
  },
  [screen.Medium.media()]: {
    Half: {
      padding: 60,
      minHeight: `50vw`
    },
    ContentRight: {
      marginBottom: "6%"
    }
  }
};
