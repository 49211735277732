import React from "react";
import classNames from "classnames";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

import Grid from "../../partials/grid";
import MediaQuery from "../../containers/media-query";

import { screen } from "../../styles/variables";

import { Float } from "./rotateAndText.rotate";

class RotateAndText extends React.Component {
  state = {
    scrolling: false
  };
  componentDidMount() {
    document.addEventListener("scroll", this.scroll);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    document.removeEventListener("scroll", this.scroll);
  }
  render() {
    const { classes, content, rotate, revolutions = 2 } = this.props;
    return (
      <Controller>
        <Scene triggerHook={0} duration="200%" pin>
          {progress => (
            <div>
              <div style={this.styles()}>
                <Grid.Row justify="Center" className={classes.Row} collapsed>
                  <Grid.Item
                    Medium={6}
                    className={classNames(classes.Half, classes.HalfLeft)}
                    collapsed
                  >
                    <div className={classes.ImageWrapper}>
                      <Float
                        reset={!this.state.scrolling}
                        rotate={
                          imageAt(revolutions, progress, rotate.length) *
                          (360 / (rotate.length - 1))
                        }
                      >
                        <Image
                          revolutions={revolutions}
                          rotate={rotate}
                          progress={progress}
                          classes={classes}
                        />
                      </Float>
                    </div>
                  </Grid.Item>
                  <Grid.Item
                    Medium={6}
                    className={classNames(classes.Half, classes.HalfRight)}
                    collapsed
                  >
                    <div className={classes.Content}>
                      {content.length > 1 && (
                        <React.Fragment>
                          <MediaQuery show={screen.Medium}>
                            <Timeline totalProgress={progress} paused>
                              <div>
                                <h2>{content[0].title}</h2>
                                <p>{content[0].content}</p>
                              </div>
                              {content.slice(1).map((part, i) => (
                                <Tween
                                  key={i}
                                  from={{
                                    css: {
                                      yPercent: 100,
                                      opacity: 0
                                    }
                                  }}
                                  to={{
                                    css: {
                                      yPercent: 0,
                                      opacity: 1
                                    }
                                  }}
                                >
                                  <div>
                                    <h3>{part.title}</h3>
                                    <p className={classes.ListText}>
                                      {part.content}
                                    </p>
                                  </div>
                                </Tween>
                              ))}
                            </Timeline>
                          </MediaQuery>
                          <MediaQuery hide={screen.Medium}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{
                                  css: {
                                    yPercent: 100
                                  }
                                }}
                                to={{
                                  css: {
                                    yPercent: 0
                                  }
                                }}
                              >
                                <div>
                                  <h2 style={{ marginRight: "14%" }}>
                                    {content[0].title}
                                  </h2>
                                  <p className={classes.ListText}>
                                    {content[0].content}
                                  </p>
                                  {content.slice(1).map((part, i) => (
                                    <div key={i}>
                                      <h3>{part.title}</h3>
                                      <p className={classes.ListText}>
                                        {part.content}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </Tween>
                            </Timeline>
                          </MediaQuery>
                        </React.Fragment>
                      )}
                    </div>
                  </Grid.Item>
                </Grid.Row>
                <MediaQuery hide={screen.Medium}>
                  <Tween
                    paused
                    totalProgress={Math.min(1, progress * content.length)}
                    from={{
                      css: {
                        opacity: 0
                      }
                    }}
                    to={{
                      css: {
                        opacity: 1
                      }
                    }}
                  >
                    <div className={classes.Backdrop} />
                  </Tween>
                </MediaQuery>
              </div>
            </div>
          )}
        </Scene>
      </Controller>
    );
  }
  scroll = () => {
    clearTimeout(this.timeout);
    if (!this.state.scrolling) {
      this.setState({
        scrolling: true
      });
    }
    this.timeout = setTimeout(() => {
      this.setState({
        scrolling: false
      });
    }, 200);
  };
  styles() {
    const styles = {
      backgroundImage: []
    };
    const { color_1, color_2, image } = this.props.custom_background;
    if (color_1 && color_2) {
      const color1 = hexToRgb(color_1);
      const color2 = hexToRgb(color_2);
      if (color1 && color2)
        styles.backgroundImage.push(
          `linear-gradient(to bottom, rgba(${color1.join(
            ","
          )}, .3) 0%,rgba(${color2.join(",")}, .3) 100%)`
        );
    }
    if (image) {
      styles.backgroundImage.push(`url(${image})`);
      styles.backgroundSize = "cover";
      styles.backgroundPosition = "contain";
    }
    styles.backgroundImage = styles.backgroundImage.join(",");
    return styles;
  }
}

function Image(props) {
  return props.rotate.map((image, i) => (
    <img
      key={i}
      src={image.url || image.source_url}
      alt=""
      className={classNames(
        props.classes.Image,
        i > 0 ? props.classes.ImageStack : null
      )}
      style={{
        visibility:
          imageAt(props.revolutions, props.progress, props.rotate.length) === i
            ? "visible"
            : "hidden"
      }}
    />
  ));
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

function revolutionProgress(revolutions, progress) {
  let p = progress;
  let c = 0;
  while (p > 1 / revolutions && c <= revolutions) {
    c++;
    p -= 1 / revolutions;
  }
  return p;
}
function imageAt(revolutions, progress, length) {
  var p = revolutionProgress(revolutions, progress);
  return Math.floor(p * (length * revolutions - revolutions));
}

export default RotateAndText;
export { RotateAndText };
