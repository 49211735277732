import withStyles from "react-jss";
import Container from "./grid.container";
import ContainerStyles from "./grid.container.styles";
import Row from "./grid.row";
import RowStyles from "./grid.row.styles";
import Item from "./grid.item";
import ItemStyles from "./grid.item.styles";

const Grid = {
  Container: withStyles(ContainerStyles)(Container),
  Row: withStyles(RowStyles)(Row),
  Item: withStyles(ItemStyles)(Item)
};
export default Grid;
export { Grid, Container, ContainerStyles, Row, RowStyles, Item, ItemStyles };
