import React from "react";
import classNames from "classnames";

import Grid from "../../partials/grid";
import {} from "../../utilities";

class Header extends React.Component {
  state = {
    show: true
  };
  componentDidMount() {
    document.addEventListener("scroll", this.scroll);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.scroll);
  }
  render() {
    const { logos, classes } = this.props;
    return (
      <header
        className={classNames(
          classes.Header,
          this.state.show && classes.Display
        )}
      >
        <Grid.Row>
          <Grid.Item Mobile={6} Small={6}>
            <img
              src={logos.primary.url || logos.primary.source_url}
              alt={logos.primary.alt}
              className={classes.Logo}
            />
          </Grid.Item>
          <Grid.Item Mobile={6} Small={6}>
            <img
              src={logos.secondary.url || logos.secondary.source_url}
              alt={logos.secondary.alt}
              className={classNames(classes.Logo, classes.LogoRight)}
            />
          </Grid.Item>
        </Grid.Row>
      </header>
    );
  }
  scroll = () => {
    if (window.scrollY > 10 && this.state.show) {
      this.setState({
        show: false
      });
    } else if (window.scrollY <= 10 && !this.state.show) {
      this.setState({
        show: true
      });
    }
  };
}
export default Header;
