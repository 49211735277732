import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

const Styles = {
  Root: {
    display: "inline-block",
    height: "1em",
    overflow: "visible",
    verticalAlign: "baseline"
  },
  Text: {
    transition: "fill .2s ease-out"
  },
  Underline: {
    transition: "fill .2s ease-out"
  },
  Outline: {
    fill: "transparent",
    stroke: "currentColor",
    strokeWidth: "2px"
  },
  Fill: {
    fill: "currentColor",
    stroke: "currentColor",
    strokeWidth: "2px"
  }
};

class Highlight extends React.Component {
  state = {
    width: null
  };
  textRef = React.createRef();
  componentDidMount() {
    this.setState({
      width: this.textRef.getBBox().width
    });
  }
  componentWillReceiveProps() {
    if (this.textRef.getBBox().width !== this.state.width) {
      this.setState({
        width: this.textRef.getBBox().width
      });
    }
  }
  render() {
    const { underline, classes, children, fill, y = 1 } = this.props;
    const { width } = this.state;
    return (
      <svg
        className={classes.Root}
        style={{
          width: this.state.width,
          height: underline && "1.6em"
        }}
      >
        <text ref={ref => (this.textRef = ref)} y={`${y}em`}>
          <tspan
            className={classNames(
              classes.Text,
              fill && (fill.text || fill === true)
                ? classes.Fill
                : classes.Outline
            )}
          >
            {children}
          </tspan>
        </text>
        {underline && (
          <rect
            y="1.3em"
            height="0.3em"
            width={width}
            className={classNames(
              classes.Underline,
              fill && (fill.underline || fill === true)
                ? classes.Fill
                : classes.Outline
            )}
          />
        )}
      </svg>
    );
  }
}

export default withStyles(Styles)(Highlight);
export { Highlight, Styles };
