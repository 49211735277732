import React from "react";

import Loadingscreen from "./containers/loadingscreen";
import Wrapper from "./containers/wrapper";
import Settings from "./campaign-settings.json";

const Content = localStorage.getItem("sicalight");

class App extends React.Component {
  state = {
    cached: Content !== null,
    loaded: Content !== null,
    page: Content && JSON.parse(Content)
  };
  componentDidMount() {
    fetch(`${Settings.WP}wp-json/wp/v2/pages/${Settings.SicaLight}`)
      .then(res => res.json())
      .then(page => {
        if (
          !this.state.page ||
          new Date(page.modified) > new Date(this.state.page.modified)
        ) {
          this.setState({
            loaded: true,
            page
          });
          localStorage.setItem("sicalight", JSON.stringify(page));
        }
      });
  }
  render() {
    const { cached, loaded, page } = this.state;
    if (!loaded) return <Loadingscreen />;
    return (
      <div className="App">
        {!cached && <Loadingscreen show={false} />}
        <Wrapper {...page.acf} children={page.acf.sections} />
      </div>
    );
  }
}
export default App;
