import React from "react";
import classNames from "classnames";
import withStyles from "react-jss";

import { Scroller } from "../containers/scroll-provider";

import { screen } from "../styles/variables";

const Styles = {
  Measure: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0
  },
  Scrollbar: {
    position: "fixed",
    left: "30px",
    right: "30px",
    bottom: "50px",
    zIndex: 10,
    width: "auto",
    height: 2,
    backgroundColor: "#fff",
    transition: "opacity .25s ease",
    boxShadow: "0px 1px 3px 1px rgba(0,0,0,.3)"
  },
  hide: {
    opacity: 0
  },
  handle: {
    marginTop: -2,
    marginBottom: -2,
    height: 6,
    borderRadius: 4,
    backgroundColor: "#fff",
    transitionProperty: "height, width",
    transitionTimingFunction: "ease",
    transitionDuration: ".35s",
    boxShadow: "1px 3px 3px 0px rgba(0,0,0,.3)"
  },
  [screen.Medium.media()]: {
    Scrollbar: {
      top: "20%",
      bottom: "20%",
      margin: 0,
      left: 30,
      right: "auto",
      width: 2,
      height: "auto",
      boxShadow: "none"
    },
    handle: {
      marginLeft: -2,
      marginRight: -2,
      marginTop: 0,
      marginBottom: 0,
      boxShadow: "none"
    }
  },
  [screen.MediumLarge.media()]: {
    Scrollbar: {
      left: 60
    }
  }
};

class Scrollbar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Scroller start={0} end={0}>
        {({ progress }) => {
          return (
            <div className={classes.Measure}>
              <div
                className={classNames(
                  classes.Scrollbar,
                  Math.floor(progress * 100) === 0 && classes.hide
                )}
              >
                <div
                  className={classes.handle}
                  style={{
                    [window.innerWidth >= screen.Medium.size
                      ? "height"
                      : "width"]: progress * 100 + "%"
                  }}
                />
              </div>
            </div>
          );
        }}
      </Scroller>
    );
  }
}
export default withStyles(Styles)(Scrollbar);
export { Scrollbar };
