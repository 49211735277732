import React from "react";
import * as Animate from "react-transition-group";
import { Tween } from "react-gsap";
import Highlight from "./partials/highlight";

export const Transition = ({
  _in,
  children,
  mountOnEnter,
  unmountOnExit,
  timeout,
  ...props
}) => (
  <Animate.Transition
    in={_in}
    timeout={timeout}
    mountOnEnter={mountOnEnter}
    unmountOnExit={unmountOnExit}
  >
    {state => {
      return (
        <Tween
          ease="Back.easeInOut"
          duration={timeout / 1000}
          {...props}
          paused={true}
          playState={
            state === "exiting"
              ? Tween.playState.reverse
              : state === "entering"
              ? Tween.playState.play
              : null
          }
        >
          {children}
        </Tween>
      );
    }}
  </Animate.Transition>
);

export function debounce(callback, wait, immediate = false) {
  let timeout = null;

  return function() {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
}

export function throttle(callback, timeout) {
  let wait = false;
  return (...args) => {
    let later = () => {
      callback.apply(this, args);
    };
    if (!wait) {
      later();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, timeout);
    }
  };
}

export function parseHighlight(text, highlight) {
  if (!highlight) return text;
  var titleText = text.split(" ");
  var highlightIndexes = [];
  highlight.split(" ").forEach(highlight => {
    if (titleText.indexOf(highlight) > -1) {
      highlightIndexes.push(titleText.indexOf(highlight));
    }
  });
  highlightIndexes.sort();
  var _text = highlightIndexes.map((index, i) => {
    if (highlightIndexes.length - 1 < i + 1) {
      i = highlightIndexes.length - 1;
    }
    return titleText.slice(index + 1, highlightIndexes[i + 1]);
  });
  _text.unshift(titleText.slice(0, highlightIndexes[0]));
  return _text.map((str, i) => (
    <React.Fragment key={i}>
      {` ${str.join(" ")} `}
      {i in highlightIndexes && (
        <Highlight>{`${titleText[highlightIndexes[i]]} `}</Highlight>
      )}
    </React.Fragment>
  ));
}

export function innerHTML(c) {
  return {
    __html: c
  };
}
