import React from "react";
import classNames from "classnames";

const Row = function(props) {
  const { align, justify, className, collapsed, classes, ...p } = props;
  return (
    <div
      {...p}
      className={classNames(
        classes.Row,
        classes[align] || null,
        classes[justify] || null,
        collapsed && classes.Collapsed,
        className
      )}
    />
  );
};
export default Row;
export { Row };
