import { screen } from "../../styles/variables";
export default {
  Half: {
    position: "relative",
    padding: [60, 15],
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },

  Row: {},
  ImageWrapper: {
    position: "relative",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  Image: {
    width: "100%"
  },
  ImageStack: {
    position: "absolute",
    top: 0,
    left: 0
  },
  Content: {
    maxWidth: 560,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    zIndex: 2
  },
  ListText: {
    fontSize: "1.6rem"
  },
  Backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)"
  },
  [screen.Medium.media()]: {
    Half: {
      paddingLeft: 60,
      paddingRight: 60
    },
    ImageWrapper: {
      marginRight: 30,
      maxWidth: 600
    },
    Content: {
      marginLeft: 0
    }
  },
  [screen.Medium.media("max")]: {
    HalfLeft: {
      padding: [0, 15]
    },
    HalfRight: {
      position: "absolute",
      bottom: 0,
      left: 0
    }
  }
};
