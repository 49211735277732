import { screen } from "../../styles/variables";
export default {
  Root: {
    padding: [60, 15],
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  Wrapper: {
    position: "relative"
  },
  TextTop: {
    position: "absolute",
    top: 0,
    paddingTop: "4rem",
    maxWidth: "100%",
    whiteSpace: "nowrap"
  },
  TextBottom: {
    position: "absolute",
    bottom: 0,
    paddingBottom: "2rem",
    maxWidth: "100%"
  },
  TextLeft: {
    left: 0
  },
  TextCenter: {
    left: "50%",
    transform: "translateX(-50%)"
  },
  TextRight: {
    right: 0
  },
  Image: {
    borderRadius: 1000,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 550
  },
  [screen.Medium.media()]: {
    Root: {
      paddingLeft: 60,
      paddingRight: 60,
      minHeight: `${(100 / 16) * 9}vw`
    },
    TextTop: {
      paddingTop: "5rem"
    },
    Image: {
      maxWidth: 710
    }
  }
};
