import React from "react";

import Title from "../../partials/title";
import { innerHTML } from "../../utilities";

class Hero extends React.Component {
  render() {
    const { title, content, classes } = this.props;

    return (
      <div className={classes.Root}>
        <div className={classes.Limiter}>
          <Title {...title} className={classes.Title} />
          <div
            className={classes.Text}
            dangerouslySetInnerHTML={innerHTML(content)}
          />
        </div>
      </div>
    );
  }
}
export default Hero;
export { Hero };
