import { screen } from "./variables";
export default {
  base: {
    default: {
      fontFamily: "Tajawal, Helvetica",
      fontWeight: "normal",
      lineHeight: "1.5",
      fontSize: "1.8rem"
    },
    headings: {
      fontFamily: "Gilroy, Arial",
      fontWeight: "normal",
      fontStyle: "normal",
      lineHeight: "1.1",
      marginBottom: ".3em",
      marginTop: 0
    }
  },
  headings: {
    h1: {
      fontSize: 40,
      textTransform: "uppercase",
      [screen.Small.media()]: {
        fontSize: 60
      }
    },
    h2: {
      fontSize: 30,
      [screen.Small.media()]: {
        fontSize: 40
      }
    }
  }
};
