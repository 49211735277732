import React from "react";
import { Timeline, Tween } from "react-gsap";
import Logo from "../../static/images/logo.svg";

class Loadingscreen extends React.Component {
  render() {
    const { classes, show = true, ...props } = this.props;
    if (show) {
      return (
        <div className={classes.Root} {...props}>
          <Tween
            from={{
              opacity: 0
            }}
            to={{
              opacity: 1
            }}
          >
            <img src={Logo} alt="" className={classes.Logo} />
          </Tween>
        </div>
      );
    }
    return (
      <Timeline
        target={
          <div className={classes.Root} {...props}>
            <Tween
              duration={0.3}
              from={{
                opacity: 1
              }}
              to={{
                opacity: 0
              }}
            >
              <img src={Logo} alt="" className={classes.Logo} />
            </Tween>
          </div>
        }
      >
        <Tween
          to={{
            width: "100px",
            height: "100px"
          }}
        />
        <Tween
          delay={0.25}
          ease="Back.easeIn"
          from={{
            top: "50%"
          }}
          to={{
            top: "-50%"
          }}
        />
      </Timeline>
    );
  }
}

export default Loadingscreen;
