import { screen } from "../../styles/variables";
export default {
  Root: {
    position: "relative",
    overflow: "hidden",
    minHeight: "100vh"
  },
  Wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  Title: {
    marginTop: 0
  },
  Link: {
    alignSelf: "flex-end",
    margin: 0
  },
  [screen.Medium.media()]: {
    Root: {
      paddingLeft: 60,
      paddingRight: 60,
      minHeight: `${(100 / 16) * 9}vw`
    }
  }
};
