export default {
  Left: {
    textAlign: "left"
  },
  Center: {
    textAlign: "center"
  },
  Right: {
    textAlign: "right"
  }
};
