import React from "react";
// import { throttle } from "../utilities";

class ScrollProvider extends React.Component {
  componentDidMount() {
    document.addEventListener("scroll", this.scroll);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.scroll);
  }
  render() {
    return this.props.children;
  }
  scroll = () => {
    ScrollProvider.scrolls.map(
      scroll =>
        scroll.mounted &&
        scroll.calculateState().progress !== scroll.state.progress &&
        scroll.forceUpdate()
    );
  };
}
ScrollProvider.scrolls = [];

class Scroller extends React.Component {
  state = {
    progress: 0
  };
  ref = React.createRef();
  componentDidMount() {
    this.mounted = true;
    ScrollProvider.scrolls.push(this);
    const state = this.calculateState();
    this.setState(state);
  }
  componentWillUpdate() {
    const state = this.calculateState();
    if (
      state.progress !== this.state.progress &&
      state.progress >= 0 &&
      state.progress <= 1
    ) {
      this.setState(state);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    return React.cloneElement(
      typeof this.props.children === "function"
        ? this.props.children(this.state)
        : this.props.children,
      {
        ref: this.ref,
        ...this.state
      }
    );
  }
  calculateState() {
    let state = {
      progress: 0,
      event: ""
    };
    const ref = this.ref.current;
    const height = window.innerHeight;
    const rect = ref.getBoundingClientRect();
    const start = rect.top - height * this.props.start;
    const end = rect.top - height + rect.height + height * this.props.end;
    if (start > 0) {
      state.progress = 0;
    } else if (start <= 0 && end >= 0) {
      state.progress = Math.abs(start) / (Math.abs(start) + end);
    } else {
      state.progress = 1;
    }
    return state;
  }
}
Scroller.defaultProps = {
  start: 1,
  end: 0
};
export { ScrollProvider, Scroller };
