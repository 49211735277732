import React from "react";

class MediaQuery extends React.Component {
  state = {
    mounted: false,
    show: null
  };
  componentDidMount() {
    this.setState({
      mounted: true,
      show: this.calc()
    });
    MediaQuery.instances.push(this);
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.show !== this.props.show ||
      prevProps.hide !== this.props.hide
    ) {
      this.update();
    }
  }
  componentWillUnmount() {
    MediaQuery.instances.splice(MediaQuery.instances.indexOf(this), 1);
  }
  render() {
    return this.state.show ? this.props.children : null;
  }
  update() {
    this.setState({
      show: this.calc()
    });
  }
  calc() {
    const { show, hide } = this.props;
    if (show) {
      if (show instanceof Array)
        return show.filter(query => this.inRange(query)).lengt > 0;
      else return this.inRange(show);
    } else if (hide) {
      if (hide instanceof Array)
        return !hide.filter(query => this.inRange(query)).lengt > 0;
      else return !this.inRange(hide);
    } else return true;
  }
  inRange(query) {
    var _query, direction;
    if (query instanceof Array) {
      _query = query[0];
      direction = query[1];
    } else {
      _query = query;
      direction = query.args.direction;
    }
    if (direction === "max") {
      return window.innerWidth <= this.getSize(_query, direction);
    } else {
      return window.innerWidth > this.getSize(_query, direction);
    }
  }
  getSize(query, direction) {
    return (
      this.toPixels(query) +
      (direction === "max" && query.args.direction !== "max"
        ? -1
        : direction === "min" && query.args.direction !== "min"
        ? 1
        : 0)
    );
  }
  toPixels(query) {
    switch (query.args.unit.toLowerCase()) {
      case "rem":
        return query.size * 10; // 1 rem = 10px by default.
      default:
        return query.size;
    }
  }
}
MediaQuery.instances = [];

if (window) {
  window.addEventListener("resize", function() {
    MediaQuery.instances.forEach(function(instance) {
      if (instance.state.mounted && instance.calc() !== instance.state.show) {
        instance.update();
      }
    });
  });
}

export default MediaQuery;
