import facebook from "./icon.facebook";
import linkedin from "./icon.linkedin";
import youtube from "./icon.youtube";

export default {
  facebook,
  linkedin,
  youtube
};
export { facebook, linkedin, youtube };
