import React from "react";
import Section from "./section";
import withStyles from "react-jss";

import Header from "../partials/header";
import Footer from "../partials/footer";
import Scrollbar from "../partials/scrollbar";
import Contact from "../partials/contact";
import { ScrollProvider } from "./scroll-provider";

const Styles = {
  Root: {
    position: "relative"
  },
  Main: {
    position: "relative"
  },
  Contact: {
    opacity: 0
  }
};

class Wrapper extends React.Component {
  state = {
    section: 0
  };
  render() {
    const { logos, some, classes, children } = this.props;
    return (
      <div className={classes.Root}>
        <ScrollProvider>
          <Header logos={logos} />
          <main className={classes.Main}>
            {children.map((props, i) => {
              return (
                <Section
                  {...props}
                  key={i}
                  index={i}
                  onEnter={this.onEnter}
                  scrollTo={this.scrollTo}
                />
              );
            })}
            <Scrollbar />
          </main>
          <Footer logo={logos.primary} links={some} />
          <Contact />
        </ScrollProvider>
      </div>
    );
  }
  scrollTo = index => {
    const section = this[`sections${index}`];
    if (section && section.current) {
      section.current.scrollIntoView(true, {
        smooth: true
      });
    }
  };
  onEnter = (i, type) => {
    if (
      (type === "enter" && i === this.state.section) ||
      (type === "leave" && (i === 0 || i + 1 !== this.state.section))
    )
      return;
    this.setState({
      section: i
    });
  };
}

export default withStyles(Styles)(Wrapper);
export { Wrapper };
