import React from "react";

export default function() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46.436"
      height="46.435"
      viewBox="0 0 46.436 46.435"
    >
      <path
        fill="currentColor"
        opacity=".83"
        d="M23.217 0A23.38 23.38 0 0 0 0 23.217a23.38 23.38 0 0 0 23.217 23.218 23.38 23.38 0 0 0 23.218-23.218A23.38 23.38 0 0 0 23.217 0zm-5.8 34.463h-5.8V14.148h5.8zm-2.539-21.4a2.9 2.9 0 1 1 2.9-2.9c-.363 1.451-1.451 2.9-2.9 2.9zm22.855 21.4h-5.8v-12.7c0-1.451-.363-2.539-2.177-2.539a3.711 3.711 0 0 0-3.628 2.539v12.7h-5.8V14.148h5.8v1.814a11.862 11.862 0 0 1 5.8-1.814c1.814 0 5.8 1.088 5.8 7.981v12.334zm0 0"
      />
    </svg>
  );
}
