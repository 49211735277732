import React from "react";
import withStyles from "react-jss";

import Background from "../partials/background";
import * as Sections from "../sections";
import { debounce } from "../utilities";

const Styles = {
  Root: {
    width: "100%",
    position: "relative",
    zIndex: 5,
    overflow: "hidden"
  },
  Content: {
    position: "relative",
    zIndex: 5
  }
};

class Section extends React.Component {
  section = React.createRef();

  componentDidMount() {
    // document.addEventListener("scroll", this.scrollSnap, true);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollSnap, true);
  }
  render() {
    const {
      classes,
      acf_fc_layout,
      onEnter,
      progress,
      background,
      ...props
    } = this.props;
    if (!(acf_fc_layout in Sections)) return null;
    const Content = Sections[acf_fc_layout];
    return (
      <section ref={this.section} className={classes.Root}>
        <Background parallax={this.props.index === 2} background={background}>
          <div className={classes.Content}>
            <Content {...props} />
          </div>
        </Background>
      </section>
    );
  }
  scrollSnap = debounce(
    () => {
      const element = this.section.current;
      if (!element) {
        return;
      }
      if (
        window.scrollY < element.offsetTop + element.clientHeight / 3 &&
        window.scrollY > element.offsetTop - element.clientHeight / 3
      ) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    100,
    true
  );
}

export default withStyles(Styles)(Section);
export { Section, Styles };

/*
<div className={classes.Content}>
  <Content {...props} />
</div>
*/
