import React from "react";
import classNames from "classnames";

const Container = function(props) {
  const { size, align, classes, children, className, ...p } = props;
  const ParentClasses = [classes.Container, className];
  if (size in classes) {
    ParentClasses.push(classes[size]);
  } else if (size !== "Default") {
  }
  return (
    <div {...p} className={classNames(ParentClasses)} children={children} />
  );
};
export default Container;
export { Container };
