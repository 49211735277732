import React from "react";

import Title from "../../partials/title";
import Grid from "../../partials/grid";
import { innerHTML } from "../../utilities";
import { Transition } from "react-spring/renderprops";

function content(props) {
  const { title, content, scrollTo, classes, background, ...p } = props;
  return (
    <div>
      <Grid.Container className={classes.Root}>
        <Grid.Row justify="Center" collapsed>
          <Grid.Item Medium={6} collapsed>
            <Transition
              items={true}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
            >
              {show =>
                show &&
                (styles => (
                  <div style={styles}>
                    <Title {...title} />
                    <div {...p} dangerouslySetInnerHTML={innerHTML(content)} />
                  </div>
                ))
              }
            </Transition>
          </Grid.Item>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
}

export default content;
export { content };
