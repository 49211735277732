class MediaQuery {
  constructor(size, args) {
    this.size = size;
    this.args = Object.assign(
      {
        unit: "px",
        queries: ["screen and", null],
        direction: "min"
      },
      args
    );
  }
  media(direction = this.args.direction) {
    let queries;
    if (this.args.queries) {
      queries = this.args.queries.slice();
    } else {
      queries = [null];
    }
    if (queries.indexOf(null) > -1)
      queries[queries.indexOf(null)] = `(${
        "max" === direction ? "max" : "min"
      }-width: ${this.size +
        ("max" === direction && "max" !== this.args.direction
          ? -1
          : "max" !== direction && "max" === this.args.direction
          ? 1
          : 0)}${this.args.unit || "px"})`;
    return `@media ${queries.join(" ")}`;
  }
}

export const screen = {
  Mobile: new MediaQuery(567, {
    direction: "max"
  }),
  Small: new MediaQuery(568),
  SmallLandscape: new MediaQuery(568, {
    queries: ["screen and", "(orientation: landscape) and", null]
  }),
  Medium: new MediaQuery(998),
  MediumLarge: new MediaQuery(1200)
};
