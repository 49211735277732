import { screen } from "../../styles/variables";
export default {
  Root: {
    padding: [120, 15, 60],
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "rgba(0,0,0,0.3)"
  },
  Limiter: {
    width: 550
  },
  Title: {
    fontSize: "6rem"
  },
  Text: {
    maxWidth: 485
  },
  [screen.Medium.media()]: {
    Root: {
      paddingLeft: 200,
      paddingRight: 200,
      minHeight: `${(100 / 16) * 9}vw`
    },
    Title: {
      fontSize: "8rem"
    }
  }
};
