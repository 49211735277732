import { Styles as HighlightStyles } from "../highlight";
import Typography from "../../styles/typography";
import { screen } from "../../styles/variables";
export default {
  Root: {
    extend: Typography.base.headings,
    textTransform: "uppercase",
    color: "inherit",
    fontSize: "40px",
    letterSpacing: "1px",
    textDecoration: "none"
  },
  Underline: {
    extend: HighlightStyles.Underline,
    transition: "fill .25s ease-in-out"
  },
  UnderlineHover: {
    fill: "currentColor"
  },
  [screen.Medium.media()]: {
    Root: {
      fontSize: "48px"
    }
  }
};
