import React from "react";

export default function() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46.01"
      height="46.01"
      viewBox="0 0 46.01 46.01"
    >
      <path
        fill="currentColor"
        opacity=".83"
        d="M23 0a23 23 0 1 0 23 23A22.991 22.991 0 0 0 23 0zm5.664 23.814h-3.7v13.294h-5.543V23.814h-2.659v-4.74h2.659v-3.005c0-2.2 1.04-5.549 5.549-5.549h4.046v4.624H26.01a1.116 1.116 0 0 0-1.156 1.272v2.774h4.162zm0 0"
      />
    </svg>
  );
}
