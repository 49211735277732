import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";

import Highlight from "../highlight";

class Button extends React.Component {
  state = {
    hovering: false
  };
  render() {
    const {
      comp,
      children,
      underline,
      classes,
      className,
      ...props
    } = this.props;
    const { onMouseOver, onMouseOut } = this;
    const CN = classNames(
      classes.Root,
      this.state.hovering && classes.RootHover,
      className,
      typeof comp === "string" && classes[`Type_${comp}`]
    );
    return React.createElement(
      comp,
      { ...props, onMouseOut, onMouseOver, className: CN },
      <Highlight
        underline={underline}
        fill={{ underline: this.state.hovering }}
      >
        {children}
      </Highlight>
    );
  }
  onMouseOver = () => {
    console.log("MouseOver", this.state.hovering);
    if (this.state.hovering) return;
    this.setState({
      hovering: true
    });
  };
  onMouseOut = () => {
    console.log("MouseOut", this.state.hovering);
    if (!this.state.hovering) return;
    this.setState({
      hovering: false
    });
  };
}

Button.defaultProps = {
  comp: "button",
  children: "",
  underline: true
};

Button.propTypes = {
  comp: propTypes.oneOfType([
    propTypes.string,
    propTypes.instanceOf(React.Component)
  ]).isRequired,
  children: propTypes.node.isRequired,
  underline: propTypes.bool.isRequired,
  classes: propTypes.object.isRequired
};

export default Button;
