import { screen } from "../../styles/variables";
export default {
  root: {
    position: "fixed",
    zIndex: 15,
    top: 0,
    width: "100%",
    transform: "translateY(-100%)",
    transition: "opacity 0.25s ease-in",
    opacity: 1
  },
  Hide: {
    opacity: 0
  },
  backdrop: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    height: "calc(100vh + 100%)",
    backgroundColor: "rgba(0,0,0,.6)",
    opacity: 0
  },
  wrapper: {
    display: "block",
    transform: "translateY(0%)"
  },
  Button: {
    backgroundColor: "rgba(0,0,0,.2)",
    border: "4px solid #fff",
    borderTop: "none",
    fontSize: "2.5rem",
    padding: 12,
    width: 180,
    color: "#fff",
    textTransform: "uppercase",
    fontFamily: "Gilroy",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 100%)",
    overflow: "hidden"
  },
  ButtonText: {
    display: "block",
    textAlign: "center",
    transform: "translateY(0%)"
  },
  ButtonTextOpen: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -120%)"
  },
  box: {
    position: "relative",
    zIndex: 2,
    padding: 15,
    border: "4px solid #fff",
    borderTop: "none",
    backgroundColor: "#181F27",
    backgroundImage:
      "linear-gradient(180deg, rgb(24, 31, 39) 0%, rgb(55, 58, 64) 100%)",
    width: "90%",
    margin: "0 auto"
  },
  form: {
    margin: "0 -9px",
    display: "flex",
    flexWrap: "wrap"
  },
  formgroup: {
    padding: "0 9px",
    width: "100%",
    color: "#fff",
    fontFamily: "Gilroy",
    fontSize: "1.8rem"
  },
  inputgroup: {
    width: "50%",
    marginBottom: 18
  },
  input: {
    width: "100%",
    backgroundColor: "transparent",
    color: "#fff",
    padding: "5px 10px",
    fontFamily: "Gilroy",
    fontSize: "1.6rem",
    marginTop: "5px",
    border: "2px solid #fff"
  },
  ButtonSubmit: {
    fontSize: "4rem",
    fontFamily: "Gilroy",
    color: "#fff",
    backgroundColor: "transparent",
    border: "none"
  },
  [screen.Medium.media()]: {
    root: {
      right: 0,
      width: 560,
      height: "100%",
      transform: "translateX(100%)"
    },
    backdrop: {
      height: "100%",
      width: "calc(100vw + 100%)"
    },
    wrapper: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(0%, -50%)",
      width: "100%"
    },
    Button: {
      left: 0,
      top: "50%",
      bottom: "auto",
      transform: "translate(-65%, -50%) rotateZ(90deg)"
    },
    box: {
      padding: "30px 60px",
      width: "100%",
      borderTop: "4px solid #fff",
      borderRight: "none"
    },
    error: {
      color: "#da5a5a"
    }
  }
};
