import React from "react";
import classNames from "classnames";

import { screen } from "../../styles/variables";

import Highlight from "../highlight";
import { Transition, throttle } from "../../utilities";

import Settings from "../../campaign-settings.json";

function urlEncode(obj) {
  let retur = [];
  Object.keys(obj).forEach(key => {
    retur.push(`${key}=${encodeURIComponent(obj[key])}`);
  });
  return retur.join("&");
}

class Contact extends React.Component {
  state = {
    hide: true,
    show: false,
    backdrop: false,
    box: false,
    error: false,
    success: false,
    form: {
      name: "",
      company: "",
      phone: "",
      email: "",
      message: ""
    }
  };
  componentDidMount() {
    document.addEventListener("scroll", this.scroll);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.scroll);
  }
  render() {
    const { classes } = this.props;
    return (
      <div
        className={classNames(classes.root, this.state.hide && classes.Hide)}
      >
        <Transition
          timeout={500}
          mountOnEnter
          unmountOnExit
          _in={this.state.show}
          to={{
            autoAlpha: 1
          }}
        >
          <div
            className={classes.backdrop}
            onClick={() => {
              this.setState({ show: false });
            }}
          />
        </Transition>
        <Transition
          timeout={1000}
          _in={this.state.show}
          ease="Cubic.easeOut"
          to={{
            [window.innerWidth >= screen.Medium.size ? "x" : "y"]:
              window.innerWidth >= screen.Medium.size ? "-100%" : "100%"
          }}
        >
          <div className={classes.wrapper}>
            <div className={classes.box} ref={this.boxRef}>
              {this.state.success ? (
                <>
                  <h1>
                    <Highlight>Thank you!</Highlight>
                  </h1>
                  <p>We will get in touch soon!</p>
                </>
              ) : (
                <>
                  <p>
                    If you provide your information and a description of your
                    business and your needs, we will get in touch soon!
                  </p>
                  {this.state.error && (
                    <p className={classes.error}>{this.state.error}</p>
                  )}
                  <form className={classes.form} onSubmit={this.submit}>
                    <div
                      className={classNames(
                        classes.formgroup,
                        classes.inputgroup
                      )}
                    >
                      <label>
                        Name
                        <input
                          type="text"
                          name="name"
                          value={this.state.form.name}
                          onChange={this.onChange("name")}
                          className={classes.input}
                        />
                      </label>
                    </div>
                    <div
                      className={classNames(
                        classes.formgroup,
                        classes.inputgroup
                      )}
                    >
                      <label>
                        Company
                        <input
                          type="text"
                          name="company"
                          value={this.state.form.company}
                          onChange={this.onChange("company")}
                          className={classes.input}
                        />
                      </label>
                    </div>
                    <div
                      className={classNames(
                        classes.formgroup,
                        classes.inputgroup
                      )}
                    >
                      <label>
                        Phonenumber
                        <input
                          type="text"
                          name="phone"
                          value={this.state.form.phone}
                          onChange={this.onChange("phone")}
                          className={classes.input}
                        />
                      </label>
                    </div>
                    <div
                      className={classNames(
                        classes.formgroup,
                        classes.inputgroup
                      )}
                    >
                      <label>
                        Email
                        <input
                          type="email"
                          name="email"
                          value={this.state.form.email}
                          onChange={this.onChange("email")}
                          className={classes.input}
                        />
                      </label>
                    </div>
                    <div className={classes.formgroup}>
                      <label>
                        Message
                        <textarea
                          name="Message"
                          value={this.state.form.message}
                          onChange={this.onChange("message")}
                          className={classes.input}
                        />
                      </label>
                    </div>
                    <div className={classes.formgroup}>
                      <button type="submit" className={classes.ButtonSubmit}>
                        <Highlight underline>SEND</Highlight>
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
            <button className={classes.Button} onClick={this.toggleShow}>
              <Transition
                _in={this.state.show}
                delay={0.5}
                timeout={1000}
                from={{
                  y: "-120%"
                }}
                to={{
                  y: "0%"
                }}
              >
                <span
                  className={classNames(
                    classes.ButtonText,
                    classes.ButtonTextOpen
                  )}
                >
                  Close
                </span>
              </Transition>
              <Transition
                _in={this.state.show}
                delay={0.5}
                timeout={1000}
                from={{
                  y: "0%"
                }}
                to={{
                  y: "120%"
                }}
              >
                <span className={classes.ButtonText}>Contact</span>
              </Transition>
            </button>
          </div>
        </Transition>
      </div>
    );
  }
  toggleShow = () => {
    this.setState({
      show: !this.state.show
    });
  };
  boxSwipe = direction => () => {
    if (
      (direction === "up" && window.innerWidth < screen.Medium.size) ||
      (direction === "right" && window.innerWidth >= screen.Medium.size)
    ) {
      this.setState({
        show: false
      });
    }
  };
  scroll = throttle(() => {
    if (window.scrollY >= window.innerHeight && this.state.hide) {
      this.setState({
        hide: false
      });
    } else if (window.scrollY < window.innerHeight && !this.state.hide) {
      this.setState({
        hide: true
      });
    }
  }, 50);
  onChange = field => event => {
    const form = this.state.form;
    form[field] = event.target.value;
    this.setState({
      form
    });
  };
  submit = event => {
    event.preventDefault();
    if (Object.values(this.state.form).indexOf("") >= 0) {
      this.setState({
        error: "Please fill in all fields."
      });
      return;
    }
    this.setState({
      error: false
    });
    const body = urlEncode(this.state.form);
    fetch(`${Settings.WP}wp-admin/admin-ajax.php?action=sica_contact`, {
      mode: "no-cors",
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body
    })
      .then(res => {
        console.log(res);
        this.setState({
          success: true
        });
      })
      .catch(() => {
        this.setState({
          error: [
            "Something went wrong, please try again later or email us directly at ",
            <a
              href="mailto:communication@acfloby.com"
              style={{ color: "#fff" }}
            >
              communication@acfloby.com
            </a>
          ]
        });
      });
  };
}

export default Contact;
export { Contact };
