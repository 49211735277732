export default {
  Root: {
    padding: ["8rem", 0, "6rem"],
    backgroundImage: "linear-gradient(to bottom, #171F27 0%, #393C42 100%)"
  },
  Logo: {
    width: "100%",
    maxWidth: 220,
    margin: [0, "auto", "6rem"],
    display: "block"
  },
  Links: {
    padding: 0,
    margin: [0, 0, "6rem"],
    listStyle: "none",
    display: "flex",
    justifyContent: "center"
  },
  Link: {
    color: "#fff",
    margin: [0, 15]
  }
};
